

































































.global-notice {
  height: auto;
  min-height: 60vh;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 1s;
}
.fade-slow-enter,
.fade-slow-leave-active {
  opacity: 0;
}
.fade-slow-enter-active {
  transition-delay: 1s;
}
